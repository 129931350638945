<template>
  <div class="contactus">
    <img src="/imgs/contactus/cooperation.png" alt="" class="contactus-titleimg">
    <div class="box">
      <img src="/imgs/contactus/boximg1.png" alt="">
      <div class="box-text">
        <img src="/imgs/contactus/box-text-img1.png" alt="">
        <div class="text">
          受疫情、俄乌战争、出口降低、股票低迷、房产困境、全球经济下滑、通货
          膨胀、人民币贬值等等各种因素的影响。广大中小微企业商户面临产能过剩、
          产品高度同质化、行业竞争激烈、垄断平台的降维打击、各种环境因素等五
          座大山压力山大。几乎所有的中小微企业商户都处在水深火热中，面临着融
          资难、难以维持经营、生存困难······如果您正面临以上问题，数字化进程加快，
          裂变产生更多可能，一起携手数字化转型！一起打造数字中国！只有各行各
          业联合起来共生才能共赢！
        </div>
      </div>
    </div>
    <div class="box">
      <div class="box-text">
        <img src="/imgs/contactus/box-text-img2.png" alt="">
        <div class="text">
          无界公司独创的无界SaaS帮助您在技术开发、升级、营运、
          维护中降低成本，赋能加持数字化转型升级并提供整套解决方案。
        </div>
      </div>
      <img src="/imgs/contactus/boximg2.jpg" alt="">
    </div>
    <div class="box">
      <img src="/imgs/contactus/boximg3.png" alt="">
      <div class="box-text">
        <img src="/imgs/contactus/box-text-img3.png" alt="">
        <div class="text">
          无界SaaS是集“云、网、店、端、人、单、群、社交、自媒体社交”等合一的复合经济体。无界云仓负责全球各行各业无边界经营者和无边界创业者使用各分类云仓开店、链接万物、万企、万商、万人互联、资源共享、无界批发、无界零售、数据流量储存、大数据分析、信用数据算力、算法、商品利润结算等数字化转型升级孵化服务，帮助你设计开发一款多维度的App。
        </div>
      </div>
    </div>
    <img src="/imgs/contactus/Hotline.png" alt="" class="contactus-titleimg">
    <div class="box">
      <div class="box-text">
        <img src="/imgs/contactus/box-text-img4.png" alt="">
        <div class="text">
          <div class="border-left">400-020-9995</div>
          <div class="border-left">上海市闵行区华漕镇申昆路2377号虹桥进口商品展示交易中心B区8号楼2楼时空链</div>
          <div class="border-left">linktobetter@163.com</div>
        </div>
      </div>
      <img src="/imgs/contactus/boximg4.png" alt="">
    </div>
    <div class="map-box">
      <div id="map" />
    </div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
  created() {
    window.scrollTo(0, 0)
  },
  mounted() {
    window._AMapSecurityConfig = {
      securityJsCode: 'e272e7826e26134d79c716959a106c1a'
    }
    AMapLoader.load({
      key: '43f2d873bd49d4cdc83adfeb468073e7', // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: [] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((AMap) => {
      var map = new AMap.Map('map', {
        viewMode: '2D', // 默认使用 2D 模式，如果希望使用带有俯仰角的 3D 模式，请设置 viewMode: '3D',
        zoom: 12, // 初始化地图层级
        center: [121.323488, 31.21265] // 初始化地图中心点
      })
      var marker = new AMap.Marker({
        position: new AMap.LngLat(121.323488, 31.21265),
        title: '无界信息技术有限公司'
      })
      map.add(marker)
      marker.setLabel({
        direction: 'top',
        content: "<div class='marker-info'>无界信息技术有限公司</div>" // 设置文本标注内容
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.contactus {
  .contactus-titleimg {
    margin: 5% 0;
  }
  .box {
    display: flex;
    text-align: left;
    .box-text {
      padding: 0 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .text {
        line-height: 0.2rem;
        margin-top: 5%;
        font-size: 18px;
        .border-left {
          position: relative;
          .border-left::after {
            content: "";
            position: absolute;
            left: -20px;
            top: 27%;
            width: 3px;
            height: 20px;
            background-color: #002fa7;
          }
        }
      }
    }
  }
  .map-box {
    position: relative;
    height: 300px;
    margin-top: 0.2rem;
    #map {
      height: 100%;
      position: absolute;
      left: -100%;
      right: -100%;
    }
  }
}
</style>
<style>
.amap-marker-label {
  border: 0;
  background-color: transparent;
}

.marker-info {
  border-radius: 6px;
  background-color: #fff;
  padding: 15px;
}
</style>
